.unread-message {
  background-color: #fef1e9;
  transition: background-color 0.3s ease; /* Adding transition for smooth effect */
}
.unread-message .MuiDataGrid-cell:first-child {
  border-left: 5px solid #f37123; /* Adjust the width and color of the border as needed */
}

.unread-message:hover {
    background-color: #f7e2d5 !important; /* Darker background color on hover */
  }